<!--
 * @Statement: 本代码版权及相关法律权利归作者所有，任何形式的引用、集成、修改、发行、销售等均需经作者书面授权。
 * @Author: Jenn@18179268111
 * @Date: 2021-04-01 16:08:46
 * @LastEditors: Jenn
 * @LastEditTime: 2021-04-06 13:13:07
 * @FilePath: /hblg-site-dev/src/views/app/xiaofang.vue
-->
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .list {
    margin-bottom: 20px;
    display: inline-block;
    .ant-card {
      float: left;
      margin-right: 10px;
      .ant-card-cover {
        width: 100%;
        height: 150px;
        overflow: hidden;
        .qrcode {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .image {
          width: 100%;
          height: 150px;
        }
      }
    }
  }
  .building {
    font-size: 20px;
  }
}
</style>

<template>
  <div class="container">
    <template v-if="data && data.length > 0">
      <div v-for="(item, key) in data" :key="key">
        <div class="title">{{ item.title }}</div>
        <div class="list">
          <a-card hoverable style="width: 250px" v-for="(item2, key2) in item.children" :key="key2">
            <template slot="cover">
              <!-- <div class="qrcode" :class="`android-${key}-${key2}`"></div> -->
              <div v-show="showQRCode == `android-${key}-${key2}`" class="qrcode" :class="`android-${key}-${key2}`"></div>
              <div v-show="showQRCode == `ios-${key}-${key2}`" class="qrcode" :class="`ios-${key}-${key2}`"></div>
              <img v-show="showQRCode != `android-${key}-${key2}` && showQRCode != `ios-${key}-${key2}`" class="image" :src="`/images/xiaofang.png`" />
            </template>
            <template slot="actions" class="ant-card-actions">
              <a-tooltip v-if="item2.link.datav" title="可视化大屏">
                <a-icon key="fund" type="fund" @click="open(item2.link.datav)" />
              </a-tooltip>
              <a-tooltip v-if="item2.link.web" title="访问后台">
                <a-icon key="global" type="global" @click="open(item2.link.web)" />
              </a-tooltip>
              <a-tooltip v-if="item2.link.app.android" title="安卓APP">
                <a-icon
                  key="android"
                  type="android"
                  @click="open(item2.link.app.android)"
                  @mouseenter="showQRCode = `android-${key}-${key2}`"
                  @mouseleave="showQRCode = null"
                />
              </a-tooltip>
              <a-tooltip v-if="item2.link.app.ios" title="苹果APP">
                <a-icon key="android" type="android" @click="open(item2.link.app.ios)" />
              </a-tooltip>
            </template>
            <a-card-meta :title="item2.title" :description="item2.description" />
          </a-card>
        </div>
      </div>
    </template>
    <div v-else class="building">建设中...</div>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from 'qrcodejs2';
export default {
  data() {
    return {
      data: null,
      showQRCode: null,
    };
  },
  async mounted() {
    let { data } = await axios.get('/json/xiaofang.json');
    this.data = data;
    this.$nextTick(function() {
      // setTimeout(() => {
      console.log(this.$refs);
      for (let key in data) {
        if (data[key] && data[key].children) {
          const children = data[key].children;
          for (let key2 in children) {
            if (children[key2] && children[key2].link && children[key2].link.app && children[key2].link.app.android) {
              this.bindQRCode(`android-${key}-${key2}`, children[key2].link.app.android);
            }
          }
        }
      }
      // }, 2000);
    });
  },
  methods: {
    bindQRCode(ref, text) {
      new QRCode(document.getElementsByClassName(ref)[0], {
        text,
        width: 150,
        height: 150,
        colorDark: '#333333',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.L,
        render: 'table',
      });
    },
    open(url) {
      window.open(url, '_blank');
    },
  },
};
</script>
